import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  editBackDrop: {
    background: "rgba(0,0,0,0.2)",
  },
  edit: {
    borderRadius: "2vh",
    textAlign: "center",
  },
  title: {
    textAlign: "left",
    fontWeight: "700",
  },
  content: {
    alignItems: "left",
    width: "100%",
  },
}));

/**
 * @param {function} props.setModalOpen the state function control modal open
 * @param {string} props.buttonPrompt the words show on the modal button
 * @param {string} props.title the title of modal
 * @param {html} props.content the content of modal
 * @param {html} props.iflink if the clickable to brought up this modal is link or not
 * @param {html} props.action the action of the modal
 * @param {btnhtml} props.button the button html
 * @param {boolean} props.modalOpen boolean to set the open status of the modal
 * @param {boolean} props.closeConfirm have a confirm dialog to control of the dialog
 */
const PopupModal = (props) => {
  const classes = useStyles();
  const [ifLink, setIfLink] = useState();
  const theme = useTheme();
  useEffect(() => {
    setIfLink(props.iflink);
  }, [props.iflink]);
  return (
    <div>
      {props.button ? (
        props.button
      ) : (
        <div>
          {ifLink === true ? (
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => props.setModalOpen(true)}
            >
              {props.buttonPrompt}
            </Link>
          ) : (
            <Button
              variant={props.variant ? props.variant : "outlined"}
              onClick={() => props.setModalOpen(true)}
            >
              {props.buttonPrompt}
            </Button>
          )}
        </div>
      )}

      <Dialog
        disableScrollLock={props.disableScrollLock}
        PaperProps={{
          classes: {
            root: classes.edit,
          },
          style: {
            height: "fit-content",
            width: props.width ? props.width : "100%",
            maxHeight: "none",
            minHeight: props.minHeight ? props.minHeight : "200px",
            minWidth: props.minWidth ? props.minWidth : "290px",
          },
        }}
        BackdropProps={{
          classes: {
            root: classes.editBackDrop,
          },
        }}
        open={props.modalOpen}
        onClose={() => {
          if (props.onClose) {
            props.onClose();
          }
          if (!props.closeConfirm) {
            props.setModalOpen(false);
          }
        }}
        aria-labelledby="edit-blueprint-title"
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            alignSelf: "flex-start",
            padding: "20px",
            [theme.breakpoints.down("sm")]: {
              padding: "10px",
            },
          }}
          className={classes.title}
          id="edit-blueprint-title"
        >
          {props.title
            ? props.title.length > 40
              ? props.title.substring(0, 40) + "..."
              : props.title
            : ""}
          {props.subTitle ? (
            <div>
              <br />
              <Typography variant="h5">{props.subTitle}</Typography>
            </div>
          ) : (
            <div></div>
          )}

          <IconButton
            aria-label="close"
            onClick={() => {
              if (props.onClose) {
                props.onClose();
              }
              if (!props.closeConfirm) {
                props.setModalOpen(false);
              }
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          className={classes.content}
          sx={{
            padding: "20px",
            [theme.breakpoints.down("sm")]: {
              padding: "10px",
            },
          }}
        >
          {props.content}
        </DialogContent>
        {props.action && (
          <DialogActions
            style={{
              alignSelf: "flex-end",
              width: "100%",
              padding: "10px 24px 25px",
            }}
          >
            {props.action}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default PopupModal;
