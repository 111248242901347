import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import AdminDeviceTable from "../components/AdminDeviceTable";
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";


const HostURL = "https://b432d666jc.execute-api.us-east-1.amazonaws.com/Prod";

function Admin(props) {
    const [adminData, setAdminData] = useState([]) ;
    const HostURL = "https://b432d666jc.execute-api.us-east-1.amazonaws.com/Prod";
    const {user} = useAuthenticator((context) => [context.user]);
		const [loading, setLoading] = useState(false);
    useEffect(() => {
      let active = true;
      (async () => {
          let page = 1;
          let hasMoreData = true;
          setLoading(true);
          while (hasMoreData && active) {
              try {
                  const response = await fetch(`${HostURL}/admin_info?page=${page}`, 
                  {
                      method: "GET",
                      headers: {
                      Token: user.signInUserSession.accessToken.jwtToken,
                      }
                  });
                  const data = await response.json();
                  if (data.results.length === 0) {
                      hasMoreData = false;
                      setLoading(false);
                  } else {
                      setAdminData(oldData => [...oldData, ...data['results'].map(item => {
                          return {id: item['particle_id'], ...item}
                      })]);
                      page += 1;
                  }
              } catch (error) {
                  console.error(error);
                  setLoading(false);
                  hasMoreData = false;
              }
          }
      })();
  
      return () => {
          active = false;
      };
  }, [user]);
  
    return <div style={{width: "100%"}}>
				<AdminDeviceTable rows={adminData} loading={loading} />
			</div>
}

export default Admin